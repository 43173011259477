import organizationApi from "@/apis/organization";

export default {
  data() {
    return {
      organizationName: '',
    };
  },
  mounted() {
    if (this.$route.params.orgId) {
      this.fetchOrg();
    }
  },
  methods: {
    async fetchOrg() {
      const { data } = await organizationApi.getOrganizationById(this.$route.params.orgId);
      this.organizationName = data.data.name;
    },
  },
};
